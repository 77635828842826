<template>
  <div class="conteudo-interno">
    <div class="conteudo">
      <div class="nova-comunicacao-container">
        <h1>Gerenciamento de parâmetros</h1>

        <div class="container-btns">
          <router-link :to="{ name: 'GerenciamentoStatus' }"
            class="btn-pattern btn-pattern_laranja-principal link">Voltar</router-link>
        </div>
      </div>

      <div class="tabela-informacoes">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-valor-bonus">
            <p>Alterado por</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>Ação realizada</p>
          </div>
          <div class="tabela-linha-nome">
            <p>Motivo</p>
          </div>
          <div class="tabela-linha-data-final">
            <p>Data</p>
          </div>
        </div>

        <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in historico" :key="index">
          <div class="tabela-linha-valor-bonus">
            <p>{{ item.usuario }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ item.acao }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p class="linha-inteira">{{ item.motivo }}</p>
          </div>
          <div class="tabela-linha-data-final">
            <p>{{ formatarData(item.dataCadastro) }}</p>
          </div>
        </div>

        <div class="paginacao" v-if="totalPaginas">
          <!-- <div class="paginacao"> -->
          <button :disabled="pagina === 1" @click="anteriorPag">
            Anterior
          </button>
          <p>{{ pagina }} - {{ totalPaginas }}</p>
          <button :disabled="pagina === totalPaginas" @click="proximaPag">
            Próxima
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { verStatus, buscaHistorico } from "@/services/parametros"

export default {
  name: "HistoricoParametro",

  data() {
    return {
      historico: [],
      pagina: 1,
      qtdPorPagina: 5,
      totalPaginas: 0
    };
  },

  methods: {
    verificarStatus() {
      verStatus().then((resp) => {
        this.statusAtual = resp.data;
      })
    },
    buscarHistorico() {
      console.log (this.$route.params.id)
      buscaHistorico(this.$route.params.id, this.pagina, this.qtdPorPagina).then((resp) => {
        this.historico = resp.data.lista;
        this.totalPaginas = resp.data.paginacao.totalPaginas;
      })
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    proximaPag() {
      this.pagina++;
      this.buscarHistorico();
    },
    anteriorPag() {
      this.pagina--;
      this.buscarHistorico();
    },
  },
  mounted() {
    this.buscarHistorico();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style scope lang="scss">
.link {
  text-decoration: none;
}

.conteudo {
  width: 100%;
  max-width: 1366px;
  margin: auto;
}

.linha-inteira {
  width: 100% !important;
}
</style>