import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";


export function verTodosStatus(pagina, qtdPorPagina) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().listarStatusCatalogo}/${pagina}/${qtdPorPagina}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function verStatus(id) {
    id = 1;
    return new Promise((resolve) => {
      const token = localStorage.getItem("token");
      store.commit("SET_ADICIONAR_LOADING");
      axios
        .get(`${_rotasAPI().verificarStatusAtual}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((erro) => {
          store.commit("user/SET_CARREGADO", true);
          tokenExpirado(erro, true);
        })
        .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
  }

  export function buscaHistorico(id, pagina, qtdPorPagina) {
    return new Promise((resolve) => {
      const token = localStorage.getItem("token");
      store.commit("SET_ADICIONAR_LOADING");
      axios
        .get(`${_rotasAPI().buscaHistoricoId}/${id}/${pagina}/${qtdPorPagina}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((erro) => {
          store.commit("user/SET_CARREGADO", true);
          tokenExpirado(erro, true);
        })
        .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
  }

export function alterarStatus(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().mudarStatus, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

